import { DateTime } from "luxon"
import React, { useState } from "react"
import {
    Line,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ComposedChart,
    ReferenceLine,
    ReferenceDot,
    ReferenceArea,
    Label,
    Legend,
    Customized,
} from "recharts"
import LoadingAnimation from "../../../../climateui/components/LoadingAnimation"
import renderRiskChartReferences from "./renderRiskChartReferences"
import renderCustomLegend from "./renderCustomLegend"

import DirectionalityHover from "../../../../climateui/components/Widgets/SeasonalWidgets/DirectionalityHover"
import Color from "color"
import BelowIcon from "../../../../climateui/icons/directionalityIcons/BelowIcon"
import WithinIcon from "../../../../climateui/icons/directionalityIcons/WithinIcon"
import TooltipV2 from "../../../../climateui/components/TooltipV2"
import InfoIcon from "../../../../climateui/icons/InfoIcon"
import { Stage } from "../types"
import renderRiskChartStages from "./renderRiskChartStages"
import { AxisInterval } from "recharts/types/util/types"

interface Point {
    id: string
    date: string
    y?: number
    y0?: number
    y1?: number
}

interface Plot {
    id: string
    visualization: "line" | "area"
    color: string
    points: Point[]
}

interface LegendInterface {
    type: "line" | "area" | "dot" | "line-dashed"
    label: string
    color: string
}

interface RiskOutlookChartProps {
    data: {
        plot: Plot[]
    }
    stages: Stage[]
    dottedSeparatorDate?: string
    highlightedDate?: string
    generalMin?: number
    generalMax?: number
    mainColor?: string
    showDots?: boolean
    verticalLineDate?: string
    verticalLineColor?: string
    adjustYAxis?: boolean
    domain?: [number, number]
    xTickFormatter?: (value: any) => string
    xAxisInterval?: AxisInterval | number
    yTickFormatter?: (value: any) => string
    loading?: boolean
    lowerBound?: number
    upperBound?: number
    legends?: LegendInterface[]
    xLabel?: string
    yLabel?: string
    xTooltipLabel?: string
    yTooltipLabel?: string
    description?: string // New property
    tooltip?: string
    title?: string
    icon?: React.ReactNode
}

const DEFAULT_COLOR = "#666D74"

const getRowDefaults = ({
    directionality: selectedDirectionality,
    personalizedColor,
    quantiles: _quantiles,
}: {
    directionality: number
    personalizedColor: string
    quantiles: Record<string, number>
}) => {
    const quantiles = Object.values(_quantiles)
    const defaultStyle = {
        borderRadius: "5px",
        border: "solid",
        backgroundColor: Color(personalizedColor).alpha(0.1).hexa(),
        color: Color(personalizedColor).darken(0.2).hex(),
        borderColor: Color(personalizedColor).alpha(0.4).hexa(),
    }
    return [
        {
            directionality: -1,
            icon: <BelowIcon />,
            style: -1 === selectedDirectionality ? defaultStyle : {},
            fill:
                -1 === selectedDirectionality
                    ? personalizedColor
                    : DEFAULT_COLOR,
            rowTitleLabelKey: "low_risk_label_key",
            rowTitleLabelDefault: "Low Risk",
            rowDescriptionLabelKey: "below",
            rowDescriptionLabelDefault: "Below",
            quantile: quantiles[0],
        },
        {
            directionality: 0,
            icon: <WithinIcon />,
            style: 0 === selectedDirectionality ? defaultStyle : {},
            fill:
                0 === selectedDirectionality
                    ? personalizedColor
                    : DEFAULT_COLOR,
            rowTitleLabelKey: "medium_risk_label_key",
            rowTitleLabelDefault: "Medium Risk",
            rowDescriptionLabelKey: "between",
            rowDescriptionLabelDefault: "Between",
            quantile: quantiles as [number, number],
        },
        {
            directionality: 1,
            icon: <BelowIcon className="rotate-180" />,
            style: 1 === selectedDirectionality ? defaultStyle : {},
            fill:
                1 === selectedDirectionality
                    ? personalizedColor
                    : DEFAULT_COLOR,
            rowTitleLabelKey: "high_risk_label_key",
            rowTitleLabelDefault: "High Risk",
            rowDescriptionLabelKey: "above",
            rowDescriptionLabelDefault: "Above",
            quantile: quantiles[1],
        },
    ]
}

const RiskOutlookChart: React.FC<RiskOutlookChartProps> = ({
    data,
    stages,
    dottedSeparatorDate,
    highlightedDate,
    generalMin,
    generalMax,
    verticalLineDate,
    verticalLineColor = "#000000", // Default color
    mainColor = "#D0D0D0",
    showDots = true,
    adjustYAxis = true,
    domain,
    xTickFormatter,
    xAxisInterval,
    yTickFormatter,
    loading,
    lowerBound,
    upperBound,
    legends,
    xLabel,
    yLabel,
    xTooltipLabel,
    yTooltipLabel,
    description, // New prop
    tooltip,
    title,
    icon: Icon,
}) => {
    if (loading) {
        return (
            <div className="flex justify-center items-center mt-36 body-lg">
                <LoadingAnimation />
            </div>
        )
    }

    if (!data || !data.plot || data.plot.length === 0) {
        return <div>No data available for the chart.</div>
    }

    const transformedData = data.plot.flatMap((plot) =>
        plot.points.map((point) => ({
            ...point,
            [plot.id]:
                plot.visualization === "area" ? [point.y0, point.y1] : point.y,
        }))
    )

    if (transformedData.length === 0) {
        return <div>No data points available for the chart.</div>
    }

    return (
        <>
            <div className="flex items-center mt-2 mb-2">
                <span className="ml-4 mr-2 w-6 h-6 fill-gray-60">{Icon}</span>
                <p className="label-lg text-gray-78">
                    {title} affecting the current season
                </p>
                <TooltipV2
                    position="top"
                    contentClass="w-[182px]"
                    content={<>{tooltip}</>}>
                    <div className="w-[18px] h-[18px] ml-1 fill-gray-60 cursor-pointer">
                        <InfoIcon />
                    </div>
                </TooltipV2>
            </div>
            <div>
                {description && (
                    <div className="pl-12 mb-2.5 text-left text-sm text-gray-60">
                        {description}
                    </div>
                )}
                <ResponsiveContainer
                    width="100%"
                    height={400}>
                    <ComposedChart
                        data={transformedData}
                        margin={{ left: 50, bottom: stages ? 50 : 0 }}>
                        <Legend
                            verticalAlign="top"
                            align="right"
                            layout="horizontal"
                            content={() => renderCustomLegend({ legends })}
                        />
                        <XAxis
                            axisLine={false}
                            tickLine={false}
                            padding={{ right: 5, left: 5 }}
                            dataKey="date"
                            type="category"
                            allowDuplicatedCategory={false}
                            strokeWidth={0}
                            interval={xAxisInterval ?? 0}
                            fontSize={14}
                            tickFormatter={(value) => {
                                return xTickFormatter
                                    ? xTickFormatter(value)
                                    : value
                            }}>
                            {xLabel && (
                                <Label
                                    value={xLabel}
                                    position="insideBottom"
                                />
                            )}
                        </XAxis>

                        <YAxis
                            padding={{ top: 10, bottom: 10 }}
                            domain={
                                adjustYAxis
                                    ? [
                                          (dataMin: number) =>
                                              Math.min(
                                                  dataMin,
                                                  ...transformedData.flatMap(
                                                      (point) =>
                                                          Object.values(
                                                              point
                                                          ).filter(
                                                              (
                                                                  value
                                                              ): value is number =>
                                                                  typeof value ===
                                                                  "number"
                                                          )
                                                  )
                                              ) ?? 0,
                                          (dataMax: number) =>
                                              Math.max(
                                                  dataMax,
                                                  ...transformedData.flatMap(
                                                      (point) =>
                                                          Object.values(
                                                              point
                                                          ).filter(
                                                              (
                                                                  value
                                                              ): value is number =>
                                                                  typeof value ===
                                                                  "number"
                                                          )
                                                  )
                                              ) ?? 0,
                                      ]
                                    : domain
                            }
                            axisLine={false}
                            tickLine={false}
                            strokeWidth={0}
                            tick={false}></YAxis>

                        <Customized
                            component={({ yAxisMap, xAxisMap }: any) => {
                                const y1 = yAxisMap[0].scale(100)
                                const y2 = yAxisMap[0].scale(0)
                                const middleY = (y1 + y2) / 2

                                return (
                                    <g>
                                        <text
                                            x={10}
                                            y={middleY}
                                            textAnchor="middle"
                                            fill="black"
                                            fontWeight={500}
                                            fontSize={14}
                                            origin={0}
                                            transform={`rotate(-90, 10, ${
                                                middleY - 20
                                            })`}>
                                            Risk Index
                                        </text>
                                        <foreignObject
                                            x={12.5}
                                            y={middleY + 15}
                                            width={20}
                                            height={20}>
                                            <TooltipV2
                                                position="top"
                                                contentClass="w-[182px]"
                                                content={
                                                    <>
                                                        Our severity levels are
                                                        based on the frequency
                                                        of events, as compared
                                                        to historical extremes:
                                                        High: Conditions more
                                                        extreme than a
                                                        once-in-10-year event.
                                                        Medium: Conditions more
                                                        severe than a
                                                        once-in-4-year event.
                                                        Low/None: Conditions
                                                        that are not considered
                                                        severe.
                                                    </>
                                                }>
                                                <div className="w-[18px] h-[18px] ml-1 fill-gray-60 cursor-pointer">
                                                    <InfoIcon />
                                                </div>
                                            </TooltipV2>
                                        </foreignObject>
                                    </g>
                                )
                            }}
                        />

                        {data.plot.map((plot) => {
                            if (plot.visualization === "line") {
                                return (
                                    <React.Fragment key={`fragment_${plot.id}`}>
                                        <Line
                                            type="linear"
                                            dataKey={plot.id}
                                            stroke={plot.color}
                                            strokeWidth={2}
                                            dot={
                                                showDots
                                                    ? (props) => {
                                                          const {
                                                              cx,
                                                              cy,
                                                              payload,
                                                          } = props

                                                          if (
                                                              DateTime.fromISO(
                                                                  payload.date
                                                              )
                                                                  .startOf(
                                                                      "day"
                                                                  )
                                                                  .equals(
                                                                      DateTime.fromISO(
                                                                          highlightedDate ??
                                                                              ""
                                                                      ).startOf(
                                                                          "day"
                                                                      )
                                                                  )
                                                          ) {
                                                              return (
                                                                  <circle
                                                                      cx={cx}
                                                                      cy={cy}
                                                                      r={5}
                                                                      fill={
                                                                          plot.color
                                                                      }
                                                                  />
                                                              )
                                                          }
                                                          return (
                                                              <circle
                                                                  cx={cx}
                                                                  cy={cy}
                                                                  r={2}
                                                                  fill={
                                                                      plot.color
                                                                  }
                                                                  opacity={0.5}
                                                              />
                                                          )
                                                      }
                                                    : false
                                            }
                                            strokeDasharray={
                                                dottedSeparatorDate ? "0" : "0"
                                            }
                                            data={transformedData.filter(
                                                (point) => {
                                                    return (
                                                        !dottedSeparatorDate ||
                                                        DateTime.fromFormat(
                                                            point.date,
                                                            "dd-MM-yyyy"
                                                        ) <=
                                                            DateTime.fromISO(
                                                                dottedSeparatorDate ??
                                                                    ""
                                                            )
                                                    )
                                                }
                                            )}
                                        />
                                        {/* use for later */}
                                        {dottedSeparatorDate && (
                                            <Line
                                                type="linear"
                                                dataKey={plot.id}
                                                stroke={plot.color}
                                                dot={
                                                    showDots
                                                        ? (props) => {
                                                              const {
                                                                  cx,
                                                                  cy,
                                                                  payload,
                                                              } = props
                                                              if (
                                                                  payload.date ===
                                                                  highlightedDate
                                                              ) {
                                                                  return (
                                                                      <circle
                                                                          cx={
                                                                              cx
                                                                          }
                                                                          cy={
                                                                              cy
                                                                          }
                                                                          r={5}
                                                                          fill={
                                                                              plot.color
                                                                          }
                                                                      />
                                                                  )
                                                              }
                                                              return (
                                                                  <circle
                                                                      cx={cx}
                                                                      cy={cy}
                                                                      r={2}
                                                                      fill={
                                                                          plot.color
                                                                      }
                                                                  />
                                                              )
                                                          }
                                                        : false
                                                }
                                                strokeWidth={2}
                                                strokeDasharray="3 3"
                                                data={transformedData.filter(
                                                    (point) => {
                                                        return (
                                                            DateTime.fromFormat(
                                                                point.date,
                                                                "dd-MM-yyyy"
                                                            ).toMillis() >=
                                                            DateTime.fromISO(
                                                                dottedSeparatorDate ??
                                                                    ""
                                                            ).toMillis()
                                                        )
                                                    }
                                                )}
                                            />
                                        )}
                                    </React.Fragment>
                                )
                            } else if (plot.visualization === "area") {
                                return (
                                    <Area
                                        dot={false}
                                        key={plot.id}
                                        type="linear"
                                        dataKey={plot.id}
                                        stroke={"none"}
                                        fill={plot.color}
                                        fillOpacity={0.3}
                                        // baseValue={"dataMin"}
                                    />
                                )
                            }
                            return null
                        })}
                        <Tooltip
                            formatter={(value, name) => {
                                if (typeof value === "number") {
                                    return [value.toFixed(2), name]
                                }
                                return [value, name]
                            }}
                            content={({ active, payload, label }: any) => {
                                const y = payload?.[0]?.payload?.y
                                const date = payload?.[0]?.payload?.date
                                const yMin = payload?.[0]?.payload?.yMin
                                const yMid = payload?.[0]?.payload?.yMid
                                const yMax = payload?.[0]?.payload?.yMax

                                const directionality =
                                    yMin > yMid && yMin > yMax
                                        ? -1
                                        : yMid > yMin && yMid > yMax
                                        ? 0
                                        : yMax > yMin && yMax > yMid
                                        ? 1
                                        : 0

                                if (!yMin && !yMid && !yMax) {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor: "white",
                                                padding: "10px",
                                                border: "1px solid #ccc",
                                            }}>
                                            <p
                                                style={{
                                                    marginBottom: "5px",
                                                }}>
                                                {xTooltipLabel
                                                    ? `${xTooltipLabel}: ${label}`
                                                    : `Date: ${label}`}
                                            </p>

                                            {payload.length > 0 && (
                                                <p
                                                    style={{
                                                        color: payload[0].color,
                                                    }}>
                                                    {yTooltipLabel
                                                        ? `${yTooltipLabel}: `
                                                        : `${payload[0].name}: `}
                                                    {Array.isArray(
                                                        payload[0].value
                                                    )
                                                        ? `${Number(
                                                              payload[0]
                                                                  .value[0]
                                                          ).toFixed(
                                                              2
                                                          )} - ${Number(
                                                              payload[0]
                                                                  .value[1]
                                                          ).toFixed(2)}`
                                                        : typeof payload[0]
                                                              .value ===
                                                          "number"
                                                        ? payload[0].value.toFixed(
                                                              2
                                                          )
                                                        : payload[0].value}
                                                </p>
                                            )}
                                        </div>
                                    )
                                }

                                return (
                                    <div className="bg-white p-2.5 rounded shadow border border-gray-10">
                                        <DirectionalityHover
                                            customGetRowDefaults={
                                                getRowDefaults
                                            }
                                            directionality={directionality}
                                            personalizedColor={
                                                directionality === -1
                                                    ? "green"
                                                    : directionality === 0
                                                    ? "#CC9300"
                                                    : "red"
                                            }
                                            title={
                                                "Probability Breakdown by Severity"
                                            }
                                            description={"Severity"}
                                            rightHeader={"Probability"}
                                            probabilities={{
                                                "prob_0-0.33": yMin ?? 0,
                                                "prob_0.33-0.67": yMid ?? 0,
                                                "prob_0.67-1": yMax ?? 0,
                                            }}
                                            quantiles={{
                                                "quantile_0.33":
                                                    lowerBound ?? 0,
                                                "quantile_0.67":
                                                    upperBound ?? 0,
                                            }}
                                            units={""}
                                            values={[
                                                {
                                                    key: "Date",
                                                    value: (() => {
                                                        try {
                                                            return DateTime.fromFormat(
                                                                date,
                                                                "dd-MM-yyyy"
                                                            ).toFormat(
                                                                "MMM dd yyyy"
                                                            )
                                                        } catch (error) {
                                                            return "Invalid Date"
                                                        }
                                                    })(),
                                                },
                                            ]}
                                        />
                                    </div>
                                )
                            }}
                        />
                        {generalMin !== undefined &&
                            generalMax !== undefined && (
                                <ReferenceArea
                                    y1={generalMin}
                                    y2={generalMax}
                                    fill={mainColor}
                                    fillOpacity={0.3}
                                />
                            )}
                        {renderRiskChartReferences({
                            lowerBound: lowerBound ?? 50,
                            upperBound: upperBound ?? 20,
                        })}
                        {verticalLineDate && (
                            <>
                                <ReferenceLine
                                    x={DateTime.fromISO(
                                        verticalLineDate
                                    ).toFormat("dd-MM-yyyy")}
                                    stroke={verticalLineColor}
                                    strokeWidth={2}>
                                    <Label
                                        position={"bottom"}
                                        offset={7}
                                        fontWeight={500}
                                        fontSize={14}>
                                        Today
                                    </Label>
                                </ReferenceLine>
                            </>
                        )}

                        {/* Stages */}
                        {stages && renderRiskChartStages(stages)}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </>
    )
}

export default RiskOutlookChart
