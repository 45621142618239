import { useTranslate } from "@tolgee/react"
import RiskItem from "./RiskItem"
import {
    categoryToColor,
    getRiskCategoryCoding,
    RISKOUTLOOK_VARIABLES,
} from "../riskOutlookUtils"
import { IRiskOutlookOverviewHazardModel } from "../../../../types"
import LoadingAnimation from "../../../../climateui/components/LoadingAnimation"

const RiskScoreDisplay = (props: {
    data: any[]
    loading: boolean
    yProperty: string
    title: string
}) => {
    const { t } = useTranslate()
    const { data, loading, yProperty, title = "Overall Risk" } = props

    if (loading) {
        return (
            <div className="flex justify-center mt-5 body-lg">
                <LoadingAnimation />
            </div>
        )
    }

    const hazardModels: IRiskOutlookOverviewHazardModel[] = []
    RISKOUTLOOK_VARIABLES.forEach((variable: string) => {
        const score = data[0][variable].plot[0].lastValue[yProperty] ?? 0
        const risk_bounds = data[0][variable].results[0].data.risk_bounds
        const riskType = variable
        const category = getRiskCategoryCoding(score, risk_bounds)

        hazardModels.push({
            score,
            riskType,
            category,
        })
    })

    const highestRiskModel = hazardModels.sort((a, b) => b.score - a.score)[0]

    return (
        <>
            <div className="flex flex-col mb-4">
                <div className="label-lg">{title}</div>
            </div>
            <div className="flex flex-col items-center justify-center m-auto p-[4px] w-full px-8 gap-[4px]">
                {
                    // Show only the remaining hazards, slice the first one as it is the highest
                    Object.values(hazardModels)
                        .sort((a, b) => b.score - a.score)
                        .map((item, index) => (
                            <RiskItem
                                key={index}
                                riskType={item.riskType}
                                score={item.score}
                                category={
                                    item.category as
                                        | "highRisk"
                                        | "mediumRisk"
                                        | "lowRisk"
                                }
                            />
                        ))
                }
            </div>
        </>
    )
}

export default RiskScoreDisplay
