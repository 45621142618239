import { Row } from "@tanstack/react-table"
import RiskOutlookIcon from "../../../climateui/icons/variableIcons/RiskOutlookIcon"

import { Translate } from "../../../components"

import {
    IRiskCountryOverviewModel,
    IRiskOverview,
} from "./components/RiskOverview"
import { useTranslate } from "@tolgee/react"
import Color from "color"
import { DateTime } from "luxon"
import DynamicMagnitudeIcon from "../../../climateui/icons/DynamicMagnitudeIcon"
import { useNavigate } from "react-router-dom"
import { useUI } from "../../../providers/UIProvider"
import { useRiskOutlook } from "./provider"
import { getDirectionalityHighlightColor, getRiskCategoryCoding } from './riskOutlookUtils';

const OUT_OF_SEASON = "out_of_season"

// ... (similar to YieldOverviewTableUtils.tsx, but adapted for Risk)
function AssetNameCell({ assetName }: { readonly assetName: string }) {
    return (
        <div className="flex body-lg items-center">
            <div className="w-[20px] fill-gray-60 mx-2">
                <RiskOutlookIcon />
            </div>
            <h1>{assetName}</h1>
        </div>
    )
}

function AssetModelsCell({
    riskModel,
    assetId,
}: {
    readonly riskModel: IRiskCountryOverviewModel
    readonly assetId: string
}) {
    const { t } = useTranslate()
    const navigate = useNavigate()
    const { setShowSecondSidebar } = useUI()
    const { countries } = useRiskOutlook()

    const seasonYear = parseInt(riskModel.excessive_cold.plot[0].lastValue.date)

    const currentSeason = riskModel?.stages[0]?.stage_name
    const isOutOfSeason = currentSeason === OUT_OF_SEASON
    const modelHasData = !!riskModel

    const riskCategory = getRiskCategoryCoding(riskModel?.highest_risk_score, riskModel?.bounds)
    const dataError = riskCategory === undefined && !isOutOfSeason

    const boxBaseColor = dataError
    ? "#B3B6BA"
    : isOutOfSeason
    ? "#DBDDDF"
    : getDirectionalityHighlightColor(riskCategory)

    const boxColorStyles = {
        backgroundColor: !dataError
            ? Color(boxBaseColor).alpha(0.1).hexa()
            : "white",
        color: Color(boxBaseColor).darken(0.2).hex(),
        borderColor: Color(boxBaseColor).alpha(0.4).hexa(),
    }

    let riskDescription = ""

    if (isOutOfSeason) {
        const startDate = DateTime.fromFormat(
            riskModel.stages[0].start_date as string,
            "MM-dd"
        ).set({ year: DateTime.now().year }) 
        riskDescription = "Outlook starts on " + startDate.toFormat("MM/dd")
    } else {
        riskDescription = `${t(riskCategory ?? "")} `
    }

    return (
        <div
            onClick={() => {
                if (isOutOfSeason && !modelHasData) return
                navigate(
                    assetId +
                        "?region=" +
                        riskModel.region_id
                )
                setShowSecondSidebar(true)
            }}
            role="button"
            tabIndex={0}
            className="border-[1px] rounded-md p-[10px] w-[170px] h-[80px]"
            style={{
                ...boxColorStyles,
                cursor:
                    isOutOfSeason && !modelHasData ? "not-allowed" : "pointer",
            }}>

            <div className="flex flex-col">
                <div className="flex justify-between">
                    <h1 className={isOutOfSeason ? "text-gray-60" : "text-gray-90"}>
                        {countries[riskModel?.region_id]?.name}
                    </h1>
                    { !isOutOfSeason && riskCategory !== undefined && (
                        <div className="w-[20px]">
                            <DynamicMagnitudeIcon magnitude={riskCategory} />
                        </div>
                    )}
                </div>
                <div className="flex justify-center body-md">
                    <h1 className="font-medium"> 
                        { riskDescription } 
                    </h1>
                </div>
                <div
                    className={`flex body-sm ${
                        isOutOfSeason || dataError ? "text-gray-30" : "text-gray-60"
                    }`}>
                    {`${seasonYear - 1} - ${seasonYear}`}
                </div>
            </div>
        </div>
    )
    
}

export const columns = [
    {
        id: "asset",
        header: () => <Translate labelKey="crop" />,
        cell: ({ row }: { row: Row<IRiskOverview> }) => {
            return <AssetNameCell assetName={row.original .asset_name} />
        },
    },
    {
        id: "risk_outlook",
        header: () => <Translate labelKey="risk_to_production_by_country" />,
        cell: ({ row }: { row: Row<IRiskOverview> }) => {
            const { countries } = useRiskOutlook()
            return (
                <div className="py-4 px-1 gap-2 flex items-center">
                    {row.original.asset_models.map((model) => (
                        <AssetModelsCell
                            key={
                                row.original.asset_id +
                                countries[model?.region_id]?.name
                            }
                            riskModel={model}
                            assetId={row.original.asset_id}
                        />
                    ))}
                </div>
            )
        },
    },
]