import { useTranslate } from "@tolgee/react"
import { GenericPageHeader } from "../../../../components"
import { useAccount, useAssets } from "../../../../providers"
import { useParams } from "react-router-dom"
import { gql } from "graphql-request"
import { useRiskOutlook } from "../provider"
import { SingleSelectFilter, TooltipV2 } from "../../../../climateui/components"
// import ShadedRegionsMap from "../../../../climateui/components/Map/ShadedRegionsMap"
import RiskOverviewScoreDisplay from "../components/RiskOverviewScoreDisplay"
import RiskScoreDisplay from "../components/RiskScoreDisplay"
import { useEffect, useMemo, useRef } from "react"
import { IRegion } from "../../../../types"
import {
    ArrayParam,
    StringParam,
    useQueryParam,
    withDefault,
} from "use-query-params"
import { DateTime } from "luxon"
import WidgetWrapper from "../../../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import RiskOutlookChart from "../components/RiskOutlookChart"
import RiskOutlookShadedRegionsMap from "../../../../components/widgets/RiskOutlookShadedRegionsMap"
import HeatIcon from "../../../../climateui/icons/variableIcons/HeatIcon"
import LowTemperatureIcon from "../../../../climateui/icons/variableIcons/LowTemperatureIcon"
import DroughtIcon from "../../../../climateui/icons/variableIcons/DroughtIcon"
import MeanPrecipitationIcon from "../../../../climateui/icons/variableIcons/MeanPrecipitationIcon"
import ToggleSelector from "../../../../climateui/components/Inputs/ToggleSelector"

const HAZARD_RISKS = [
    {
        hazard_risk: "excessive_heat",
        color: "#FF7100",
        title: "Damaging Heat",
        Icon: <HeatIcon />,
        definition:
            "Daily maximum temperatures above 33°C. Severity is indexed based on historical frequency (e.g., a once-in-10-year event has an index of 0.9, indicating rare and severe conditions). Highest impact: flowering and early fruiting.",
    },
    {
        hazard_risk: "excessive_cold",
        color: "#039BE5",
        title: "Frost",
        Icon: <LowTemperatureIcon />,
        definition:
            "Daily minimum temperatures below 2°C. Severity is indexed based on historical frequency (e.g., a once-in-10-year event has an index of 0.9). Highest impact: pre-flowering vegetative growth.",
    },
    {
        hazard_risk: "drought",
        color: "#A220B7",
        title: "Drought",
        Icon: <DroughtIcon />,
        definition:
            "3-month precipitation anomalies, including evapotranspiration effects. Severity is indexed based on historical frequency (e.g., once-in-10-year event has an index of 0.9). Highest impact: flowering and early fruiting.",
    },
    {
        hazard_risk: "excessive_rain",
        color: "#237CB5",
        title: "Excessive Rain",
        Icon: <MeanPrecipitationIcon />,
        definition:
            "3-week precipitation anomalies. Severity is indexed based on historical frequency (e.g., once-in-10-year event = index 0.9). Highest impact: fruiting and maturation.",
    },
]

const getRegionsAndStatesDict = (
    availableStates: IRegion[],
    countries: Record<string, IRegion>
) => {
    const statesRegionsDict: Record<string, Record<string, string>> = {}
    const regionsDict: Record<string, string> = {}
    const countriesIdsForAssetModel = new Set()
    availableStates.forEach((state) => {
        countriesIdsForAssetModel.add(state.parent_id)
    })

    if (Object.keys(countries)?.length > 0) {
        Object.keys(countries).forEach((country) => {
            if (country && countriesIdsForAssetModel.has(country)) {
                regionsDict[country] = countries[country].name
            }
        })
    }

    if (availableStates?.length > 0) {
        availableStates.forEach((region: IRegion) => {
            if (region.id && region.parent_id) {
                if (!statesRegionsDict[region.parent_id]) {
                    statesRegionsDict[region.parent_id] = {}
                }
                statesRegionsDict[region.parent_id][region.id] = region.name
            }
        })
    }

    return { statesRegionsDict, regionsDict }
}

const riskScoresQuery = `#graphql
query (
    $assetModelId: String!
    $init_time: String!
) {
    yield_outlook_model(
        filter: {
            crop_model_ids: [$assetModelId]
        }
    ) {
        results {
            region_id
            excessive_heat: risk_historical_stats(
                filter: {
                    hazard_key: "excessive_heat"
                }
            ) {
                plot(
                    configs: [
                        {
                            date: "year"
                            y: "value"
                            y1: "upcoming_value"
                        }
                    ]
                ) {
                    lastValue {
                        date
                        y
                        y1
                    }
                }
                results {
                    data {
                        risk_bounds
                    }
                }
            }
            excessive_cold: risk_historical_stats(
                filter: {
                    hazard_key: "excessive_cold"
                }
            ) {
                plot(
                    configs: [
                        {
                            date: "year"
                            y: "value"
                            y1: "upcoming_value"
                        }
                    ]
                ) {
                    lastValue {
                        date
                        y
                        y1
                    }
                }
                results {
                    data {
                        risk_bounds
                    }
                }
            }
            drought: risk_historical_stats(
                filter: {
                    hazard_key: "drought"
                }
            ) {
                plot(
                    configs: [
                        {
                            date: "year"
                            y: "value"
                            y1: "upcoming_value"
                        }
                    ]
                ) {
                    lastValue {
                        date
                        y
                        y1
                    }
                }
                results {
                    data {
                        risk_bounds
                    }
                }
            }
            excessive_rain: risk_historical_stats(
                filter: {
                    hazard_key: "excessive_rain"
                }
            ) {
                plot(
                    configs: [
                        {
                            date: "year"
                            y: "value"
                            y1: "upcoming_value"
                        }
                    ]
                ) {
                    lastValue {
                        date
                        y
                        y1
                    }
                }
                results {
                    data {
                        risk_bounds
                    }
                }
            }
            asset_id
            stages(
                filter: {
                    current_date: $init_time
                }
            ) {
                stage_name
                start_date
                end_date
            }
        }
    }
}
`

const RiskOutlookDashboard = () => {
    const { t } = useTranslate()
    const { selectedAccount } = useAccount()
    const { allAssets } = useAssets()
    const { assetId } = useParams()
    const assetModelName = allAssets?.[assetId as string]?.name

    const { states, assetModels, countries, isLoading } = useRiskOutlook()

    const selectedAssetModels = useMemo(() => {
        if (!assetId || !assetModels) return []
        return assetModels.filter((model) => model.asset_id === assetId)
    }, [assetId, assetModels])

    const { statesRegionsDict, regionsDict } = useMemo(() => {
        if (selectedAssetModels.length === 0)
            return { statesRegionsDict: {}, regionsDict: {} }

        // Create a set of just the region ids
        const selectedModelsRegionIDs = new Set(
            selectedAssetModels.map((model) => model.region_id)
        )

        return getRegionsAndStatesDict(
            states.filter(
                (state) => state.id && selectedModelsRegionIDs.has(state.id)
            ),
            countries
        )
    }, [selectedAssetModels, states, countries])

    const regionParam = withDefault(StringParam, Object.keys(regionsDict)[0])
    const previouslySelectedRegion = useRef<string | undefined>(undefined)
    const [selectedRegion, setSelectedRegion] = useQueryParam(
        "region",
        regionParam
    )

    const stateParam = withDefault(StringParam, "")
    const [selectedState, setSelectedState] = useQueryParam("state", stateParam)

    // The asset model that belongs to the selected parent region, i.e. country
    const selectedRegionAssetModel = selectedAssetModels.find(
        ({ region_id }) =>
            region_id === selectedState || region_id === selectedRegion
    )

    // Set the previouslySelectedRegion if not set
    useEffect(() => {
        if (!previouslySelectedRegion.current) {
            previouslySelectedRegion.current = selectedRegion
        }
    }, [selectedRegion])

    useEffect(() => {
        if (!selectedAccount) return
        setSelectedState("")
    }, [selectedAccount])

    const filteredStates = statesRegionsDict[selectedRegion]
        ? Object.keys(statesRegionsDict[selectedRegion])
        : []
    const isSingleCountryModel = filteredStates?.[0] === selectedRegion

    // Declare dashboard filters
    const dashboardFilters = useMemo(
        () => [
            {
                propName: "assetModelId",
                value: selectedRegionAssetModel?.id,
                loading: isLoading,
            },
            {
                propName: "init_time",
                value:
                    selectedRegionAssetModel?.newest_risk_date ??
                    DateTime.now().toUTC().toISODate(),
                loading: false,
            },
        ],
        [selectedRegionAssetModel?.id, isLoading]
    )

    // Declare options for toggle selector
    const chartDisplayOptions = {
        0: { key: "daily", value: "Risk Outlook" },
        1: { key: "historical", value: "Full Season View & Analog Years" },
    }

    const DatasetParam = withDefault(ArrayParam, ["0"] as string[])
    const [selectedDataset, setSelectedDataset] = useQueryParam(
        "dataset",
        DatasetParam
    )

    const selectedDatasetString = useMemo(() => {
        const index = parseInt(selectedDataset[0] ?? "0")
        return (
            chartDisplayOptions[index as keyof typeof chartDisplayOptions]
                ?.key ?? "historical"
        )
    }, [selectedDataset])

    return (
        <div
            className="w-full h-full p-8 overflow-y-scroll bg-gray-1.5"
            id="risk-dashboard-container">
            <GenericPageHeader
                pageTitle={
                    assetModelName && !isLoading ? (
                        <div>
                            {t("regionalRisk", "{asset} Regional Risk", {
                                asset: assetModelName,
                            })}
                        </div>
                    ) : (
                        <div className="text-gray-30">
                            {t("loadingYieldDashboard")}
                        </div>
                    )
                }
            />

            <div className="mb-[14px] flex flex-wrap gap-2 align-baseline -mt-5">
                <SingleSelectFilter
                    key="region"
                    placeholder="Region"
                    leftRightClass="left-0"
                    selected={selectedRegion}
                    setSelected={(v) => {
                        setSelectedRegion(v)
                        setSelectedState("")
                    }}
                    options={regionsDict}
                />
                {!isSingleCountryModel && (
                    <SingleSelectFilter
                        key="state"
                        placeholder={t("state")}
                        leftRightClass="left-0"
                        selected={selectedState}
                        setSelected={(v) => {
                            setSelectedState(v)
                        }}
                        options={statesRegionsDict[selectedRegion]}
                    />
                )}
                <ToggleSelector
                    options={chartDisplayOptions}
                    selectedOptions={selectedDataset as string[]}
                    updateSelectedOptions={(currElement: string) => {
                        if (selectedDataset.includes(currElement)) {
                            // If the current element is already selected and it's the only one, do nothing
                            if (selectedDataset.length === 1) {
                                return
                            }
                            // Otherwise, remove it from the selection
                            const newOptions = selectedDataset.filter(
                                (option) => option !== currElement
                            )
                            setSelectedDataset(newOptions)
                        } else {
                            // If the current element is not selected, select it and deselect others
                            setSelectedDataset([currElement])
                        }
                    }}
                />
            </div>

            <div className="flex flex-col gap-[14px] mb-[14px]">
                <div className="flex gap-[14px]">
                    <div className="grid w-5/12 gap-[14px]">
                        <div className="bg-white border rounded-lg font-roboto border-1 border-gray-14 p-[14px]">
                            <WidgetWrapper
                                component={RiskOverviewScoreDisplay}
                                query={riskScoresQuery}
                                filters={dashboardFilters}
                                selectors={{
                                    $data: "yield_outlook_model.results[]",
                                    yProperty:
                                        selectedDatasetString === "historical"
                                            ? "y"
                                            : "y1",
                                    title:
                                        selectedDatasetString === "historical"
                                            ? "Overall Current Season Risk"
                                            : "Overall Upcoming Risk",
                                }}
                            />
                        </div>
                        <div className="bg-white border rounded-lg font-roboto border-1 border-gray-14 p-[14px]">
                            <WidgetWrapper
                                component={RiskScoreDisplay}
                                query={riskScoresQuery}
                                filters={dashboardFilters}
                                selectors={{
                                    $data: "yield_outlook_model.results[]",
                                    yProperty:
                                        selectedDatasetString === "historical"
                                            ? "y"
                                            : "y1",
                                    title:
                                        selectedDatasetString === "historical"
                                            ? "Overall Current Season Risk"
                                            : "Overall Upcoming Risk",
                                }}
                            />
                        </div>
                    </div>

                    <div className="w-7/12 bg-white border rounded-lg font-roboto border-1 border-gray-14">
                        <WidgetWrapper
                            component={RiskOutlookShadedRegionsMap}
                            query={gql`
                                query ($assetModelId: String!) {
                                    yield_outlook_model(
                                        filter: {
                                            crop_model_ids: [$assetModelId]
                                        }
                                    ) {
                                        results {
                                            region {
                                                id
                                                name
                                                geojson
                                                resolution
                                            }
                                            excessive_heat: risk_historical_stats(
                                                filter: {
                                                    hazard_key: "excessive_heat"
                                                }
                                            ) {
                                                plot(
                                                    configs: [
                                                        {
                                                            date: "year"
                                                            y: "value"
                                                            y1: "upcoming_value"
                                                        }
                                                    ]
                                                ) {
                                                    lastValue {
                                                        date
                                                        y
                                                        y1
                                                        meta
                                                    }
                                                }
                                                results {
                                                    data {
                                                        risk_bounds
                                                    }
                                                }
                                            }
                                            excessive_cold: risk_historical_stats(
                                                filter: {
                                                    hazard_key: "excessive_cold"
                                                }
                                            ) {
                                                plot(
                                                    configs: [
                                                        {
                                                            date: "year"
                                                            y: "value"
                                                            y1: "upcoming_value"
                                                        }
                                                    ]
                                                ) {
                                                    lastValue {
                                                        date
                                                        y
                                                        y1
                                                        meta
                                                    }
                                                }
                                                results {
                                                    data {
                                                        risk_bounds
                                                    }
                                                }
                                            }
                                            drought: risk_historical_stats(
                                                filter: {
                                                    hazard_key: "drought"
                                                }
                                            ) {
                                                plot(
                                                    configs: [
                                                        {
                                                            date: "year"
                                                            y: "value"
                                                            y1: "upcoming_value"
                                                        }
                                                    ]
                                                ) {
                                                    lastValue {
                                                        date
                                                        y
                                                        y1
                                                        meta
                                                    }
                                                }
                                                results {
                                                    data {
                                                        risk_bounds
                                                    }
                                                }
                                            }
                                            excessive_rain: risk_historical_stats(
                                                filter: {
                                                    hazard_key: "excessive_rain"
                                                }
                                            ) {
                                                plot(
                                                    configs: [
                                                        {
                                                            date: "year"
                                                            y: "value"
                                                            y1: "upcoming_value"
                                                        }
                                                    ]
                                                ) {
                                                    lastValue {
                                                        date
                                                        y
                                                        y1
                                                        meta
                                                    }
                                                }
                                                results {
                                                    data {
                                                        risk_bounds
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            `}
                            filters={dashboardFilters}
                            selectors={{
                                $data: "yield_outlook_model.results[]",
                                yProperty:
                                        selectedDatasetString === "historical"
                                            ? "y"
                                            : "y1",
                            }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 grid-rows-2 gap-[14px]">
                    {HAZARD_RISKS.map(
                        ({ hazard_risk, color, Icon, title, definition }) => (
                            <div
                                key={`${hazard_risk}-charts`}
                                className="w-full">
                                {selectedDatasetString === "historical" ? (
                                    <div className="w-full bg-white border rounded-lg font-roboto border-1 border-gray-14 p-[14px]">
                                        <WidgetWrapper
                                            key="yearly-graph"
                                            component={RiskOutlookChart}
                                            query={`#graphql
                                    query($assetModelId: String!) {
                                        historical_stats: yield_outlook_model(filter: { crop_model_ids: [$assetModelId] }) {
                                            results {
                                                risk_historical_stats(filter: {hazard_key: "${hazard_risk}"}) {
                                                    results {
                                                        year
                                                        data {
                                                            risk_bounds
                                                            caption
                                                            tooltip
                                                        }
                                                    }
                                                    plot(configs: [
                                                            { 
                                                                id: "${hazard_risk}-line", 
                                                                visualization: "line", 
                                                                color: "${color}", 
                                                                date: "year", 
                                                                y: "value", 
                                                                yMin: "probability_low", 
                                                                yMid: "probability_mid", 
                                                                yMax: "probability_high"
                                                            }
                                                        ]) {
                                                        id
                                                        visualization
                                                        color
                                                        opacity
                                                        points {
                                                            date
                                                            y
                                                            yMin
                                                            yMid
                                                            yMax
                                                        }
                                                        lastValue {
                                                            date
                                                            y0
                                                            y1
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                `}
                                            selectors={{
                                                $data: "historical_stats.results[0].risk_historical_stats",
                                                $highlightedDate:
                                                    "historical_stats.results[0].risk_historical_stats.results[-1:].year",
                                                $generalMin:
                                                    "historical_stats.results[0].risk_historical_stats.plot[1].lastValue.y0",
                                                $generalMax:
                                                    "historical_stats.results[0].risk_historical_stats.plot[1].lastValue.y1",
                                                mainColor: color,
                                                adjustYAxis: false,
                                                domain: [0, 100],
                                                $lowerBound:
                                                    "historical_stats.results[0].risk_historical_stats.results[0].data.risk_bounds[0]",
                                                $upperBound:
                                                    "historical_stats.results[0].risk_historical_stats.results[0].data.risk_bounds[1]",
                                                legends: [
                                                    {
                                                        type: "line",
                                                        label: "Observed",
                                                        color: color,
                                                    },
                                                    {
                                                        type: "dot",
                                                        label: t("current_season_forecast", "Current season forecast"),
                                                        color: color,
                                                    },
                                                ],
                                                xTooltipLabel: "Year",
                                                yTooltipLabel: title + " Index",
                                                $description:
                                                    "historical_stats.results[0].risk_historical_stats.results[-1:].data.caption",
                                                $tooltip:
                                                    "historical_stats.results[0].risk_historical_stats.results[-1:].data.tooltip",
                                                xTickFormatter: (
                                                    value: string
                                                ) => {
                                                    const date =
                                                        DateTime.fromFormat(
                                                            value,
                                                            "yyyy"
                                                        )
                                                    return date.toFormat("yyyy")
                                                },
                                                xAxisInterval:
                                                    "preserveStartEnd",
                                                title: title,
                                                icon: Icon,
                                            }}
                                            filters={dashboardFilters}
                                        />
                                    </div>
                                ) : (
                                    <div className="w-full bg-white border rounded-lg font-roboto border-1 border-gray-14 p-[14px]">
                                        <WidgetWrapper
                                            key="yearly-graph"
                                            component={RiskOutlookChart}
                                            query={`#graphql
                                    query($assetModelId: String!, $init_time: String!) {
                                        stats: yield_outlook_model(filter: { crop_model_ids: [$assetModelId] }) {
                                            results {
                                                stages {
                                                    start_date
                                                    end_date
                                                    stage_name
                                                }
                                                risk_stats(filter: {hazard_key: "${hazard_risk}", init_time: $init_time}) {
                                                    results {
                                                        data {
                                                            risk_bounds
                                                            caption
                                                            tooltip
                                                            plot(configs: [
                                                                { id: "${hazard_risk}-line-2", visualization: "line", color: "${color}", date: "date", y: "value", yMin: "probability_low", yMid: "probability_mid", yMax: "probability_high" }
                                                                ]) {
                                                                id
                                                                visualization
                                                                color
                                                                opacity
                                                                points {
                                                                    date
                                                                    y
                                                                    yMin
                                                                    yMid
                                                                    yMax
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                
                                            }
                                        }
                                    }
                                `}
                                            selectors={{
                                                $data: "stats.results[0].risk_stats.results[0].data",
                                                $stages:
                                                    "stats.results[0].stages",
                                                dottedSeparatorDate:
                                                    DateTime.now()
                                                        .startOf("day")
                                                        .toISODate(),
                                                verticalLineDate: DateTime.now()
                                                    .startOf("day")
                                                    .toISODate(),
                                                verticalLineColor: "#B3B6BA",
                                                showDots: false,
                                                adjustYAxis: false,
                                                domain: [0, 100],
                                                xTickFormatter: (
                                                    value: string
                                                ) => {
                                                    const date =
                                                        DateTime.fromFormat(
                                                            value,
                                                            "dd-MM-yyyy"
                                                        )
                                                    const today = DateTime.now()
                                                    const dayOfMonth = date.day
                                                    const diffDays = Math.abs(
                                                        date.diff(today, "days")
                                                            .days
                                                    )

                                                    // Show full month name on the 15th of every month
                                                    if (
                                                        dayOfMonth === 15 &&
                                                        diffDays > 10
                                                    ) {
                                                        return date.toFormat(
                                                            "MMMM"
                                                        )
                                                    }

                                                    // Return empty string for other days
                                                    return ""
                                                },
                                                $lowerBound:
                                                    "stats.results[0].risk_stats.results[0].data.risk_bounds[0]",
                                                $upperBound:
                                                    "stats.results[0].risk_stats.results[0].data.risk_bounds[1]",
                                                legends: [
                                                    {
                                                        type: "line",
                                                        label: "Observed",
                                                        color: color,
                                                    },
                                                    {
                                                        type: "line-dashed",
                                                        label: "Forecast",
                                                        color: color,
                                                    },
                                                ],
                                                xTooltipLabel: "Date",
                                                yTooltipLabel: title + " Index",
                                                $description:
                                                    "stats.results[0].risk_stats.results[0].data.caption",
                                                $tooltip:
                                                    "stats.results[0].risk_stats.results[0].data.tooltip",
                                                title: title,
                                                icon: Icon,
                                            }}
                                            filters={dashboardFilters}
                                        />
                                    </div>
                                )}
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    )
}

export default RiskOutlookDashboard
