import React from "react"
import { Customized, Label, ReferenceArea, ReferenceLine } from "recharts"
import { Stage } from "../types"
import { DateTime } from "luxon"

const RISK_STAGES = {
    vegetative_growth: {
        label: "Vegetative Growth",
        color: "#149CAB",
    },
    flowering: {
        label: "Flowering",
        color: "#38761D",
    },
    fruiting: {
        label: "Fruiting",
        color: "#FF9900",
    },
    maturation: {
        label: "Maturation",
        color: "#149CAB",
    },
    harvest: {
        label: "Harvest",
        color: "#AD8406",
    },
    out_of_season: {
        label: "Out of Season",
        color: "#B3B6BA",
    },
    resting: {
        label: "Resting Period",
        color: "#B3B6BA",
    },
}

const renderRiskChartStages = (stages: Stage[]) => {
    const currentYear = DateTime.now().year

    const contextualizedStages = stages.map((stage) => {
        let startYear = currentYear
        let endYear = currentYear

        // Parse the dates without year to compare months and days
        const startDate = DateTime.fromFormat(stage.start_date, "MM-dd")
        const endDate = DateTime.fromFormat(stage.end_date, "MM-dd")
        const now = DateTime.now().set({ year: 2000 }) // Set to arbitrary year for comparison

        // If end date is before start date, it means the stage crosses to next year
        if (endDate < startDate) {
            endYear = currentYear + 1
        }

        // If both dates are in the past for current year, move both to next year
        if (startDate < now && endDate < now) {
            startYear = currentYear + 1
            endYear = currentYear + 1
        }

        return {
            ...stage,
            startDate: DateTime.fromFormat(
                `${startYear}-${stage.start_date}`,
                "yyyy-MM-dd"
            ),
            endDate: DateTime.fromFormat(
                `${endYear}-${stage.end_date}`,
                "yyyy-MM-dd"
            ),
        }
    })

    const stageColors = ["#FF6B6B", "#4ECDC4", "#45B7D1", "#FFA07A", "#98D8C8"]

    return (
        <>
            <Customized
                component={({ xAxisMap, yAxisMap }: any) => {
                    const xMin = xAxisMap[0].domain[0]
                    const xMax =
                        xAxisMap[0].domain[xAxisMap[0].domain.length - 1]

                    const xMinDate = DateTime.fromFormat(xMin, "dd-MM-yyyy")
                    const xMaxDate = DateTime.fromFormat(xMax, "dd-MM-yyyy")

                    const filteredStages = contextualizedStages.filter(
                        (stage) => {
                            return (
                                (stage.startDate <= xMaxDate &&
                                    stage.endDate >= xMinDate) ||
                                (stage.startDate >= xMinDate &&
                                    stage.startDate <= xMaxDate) ||
                                (stage.endDate >= xMinDate &&
                                    stage.endDate <= xMaxDate)
                            )
                        }
                    )

                    const cleanedStages = filteredStages.map((stage) => ({
                        ...stage,
                        startDate:
                            stage.startDate < xMinDate
                                ? xMinDate
                                : stage.startDate,
                        endDate:
                            stage.endDate > xMaxDate ? xMaxDate : stage.endDate,
                    }))

                    return (
                        <g>
                            {cleanedStages.map((stage, index) => {
                                const startX = xAxisMap[0].scale(
                                    stage.startDate.toFormat("dd-MM-yyyy")
                                )
                                const endX = xAxisMap[0].scale(
                                    stage.endDate.toFormat("dd-MM-yyyy")
                                )
                                const y = yAxisMap[0].scale(0)

                                return (
                                    <g key={stage.stage_name}>
                                        <line
                                            x1={startX}
                                            x2={endX}
                                            y1={y + 50}
                                            y2={y + 50}
                                            stroke={
                                                RISK_STAGES[
                                                    stage.stage_name as keyof typeof RISK_STAGES
                                                ]?.color
                                            }
                                            strokeWidth={4}
                                        />
                                        <text
                                            x={(startX + endX) / 2}
                                            y={y + 70}
                                            textAnchor="middle"
                                            fill={"#1A232F"}
                                            fontSize="12">
                                            {
                                                RISK_STAGES[
                                                    stage.stage_name as keyof typeof RISK_STAGES
                                                ]?.label
                                            }
                                        </text>
                                    </g>
                                )
                            })}
                        </g>
                    )
                }}
            />
        </>
    )
}

export default renderRiskChartStages
