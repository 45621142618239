import DroughtIcon from "../../../climateui/icons/variableIcons/DroughtIcon";
import HeatIcon from "../../../climateui/icons/variableIcons/HeatIcon";
import LowTemperatureIcon from "../../../climateui/icons/variableIcons/LowTemperatureIcon";
import { PrecipitationIcon } from "../../../climateui/icons/weatherVariableIcons";
import { IRiskCountryOverviewModel } from "./components/RiskOverview";
import RiskOutlookIcon from "../../../climateui/icons/variableIcons/RiskOutlookIcon";

export const RISKOUTLOOK_VARIABLES = [
    "drought",
    "excessive_heat",
    "excessive_rain",
    "excessive_cold",
]


export const getMaxRiskScore = (risk_stats: IRiskCountryOverviewModel['risk_stats']): number => {
  let maxRiskScore = 0;
  risk_stats.results.forEach((hazard_model) => {
    hazard_model.data.timeseries.forEach((timeseries) => {
      if (timeseries.value > maxRiskScore) {
        maxRiskScore = timeseries.value;
      }
    });
  });
  return maxRiskScore;
};

export const getMaxRiskScoreForHazard = (timeseries: {
    date: string
    terciles: number[]
    value: number
}[]): number => {
    let maxRiskScore = 0;
    timeseries.forEach((data) => {
        if (data.value > maxRiskScore) {
          maxRiskScore = data.value;
        }
    });
    
    return maxRiskScore;
};

export const getRiskCategoryCoding = (value: number, bounds: number[]) => {
    if(bounds.length > 2) {
        return "highRisk"
    }

    if (value > bounds[1]) {
        return "highRisk"
    } else if (value < bounds[0]) {
        return "lowRisk"
    } else {
        return "mediumRisk"
    }
};

export const getDirectionalityHighlightColor = (directionality: string) => {
    switch (directionality) {
        case "highRisk":
            return "#E42437"
        case "mediumRisk":
            return "#FDB600"
        case "lowRisk":
            return "#23AF41"
    }
};


export const getHazardIcon = (hazard: string) => {
    switch (hazard) {
        case "drought":
            return DroughtIcon;
        case "excessive_heat":
            return HeatIcon;
        case "excessive_cold":
            return LowTemperatureIcon;
        case "excessive_rain":
            return PrecipitationIcon;
        default:
            return RiskOutlookIcon;
    }
};

export const categoryToColor = {
    highRisk: 'red',
    mediumRisk: 'yellow-dark',
    lowRisk: 'green'
};