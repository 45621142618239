// TODO: Move to yield outlook folder
import { useMemo } from "react"
import ShadedRegionsMap from "../../climateui/components/Map/ShadedRegionsMap"
import { IPolygonGroup } from "../../climateui/components/Map/ShadedRegionsMap/types"
import IWidgetDefaultProps from "../../views/Seasonal/Dashboards/components/widgetLibrary/WidgetDefaultProps"
import {
    getRiskCategoryCoding,
    RISKOUTLOOK_VARIABLES,
} from "../../views/Seasonal/RiskOutlook/riskOutlookUtils"

const riskCategoryCoding: Record<string, string> = {
    highRisk: "#E42437",
    mediumRisk: "#FDB600",
    lowRisk: "#23AF41",
}

// interface ShadedRegionMapWidgetProps extends IWidgetDefaultProps {
//     __data: any
//     loading?: boolean
//     yProperty: string
//     toggleNewDirectonalityLogic: boolean
// }

const ShadedRegionMapWidget = (props : {
    __data: any,
    loading: boolean,
    yProperty: string
}) => {
    const {__data, loading, yProperty} = props

    const { categorizedRegions, categorizedPins } = useMemo(() => {
        if (!__data?.yield_outlook_model)
            return { categorizedRegions: {}, categorizedPins: [] }

        let pins: { lng: number; lat: number; color: string }[] = []
        const data = __data.yield_outlook_model.results[0] as any
        const { max_value, hazard, max_risk_bounds, locations } =
            RISKOUTLOOK_VARIABLES.reduce(
                (acc, variable) => {
                    const value = data[variable].plot[0].lastValue[yProperty]
                    return value > acc.max_value
                        ? {
                              max_value: value,
                              hazard: variable,
                              max_risk_bounds:
                                  data[variable].results[0].data.risk_bounds,
                              locations:
                                  data[variable].plot[0]?.lastValue?.meta?.data
                                      ?.locations ?? [],
                          }
                        : acc
                },
                {
                    max_value: -Infinity,
                    hazard: "",
                    max_risk_bounds: [],
                    locations: [],
                }
            )

        const riskCategory = getRiskCategoryCoding(max_value, max_risk_bounds)

        const shadedRegion: IPolygonGroup = {
            geojson: {
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        geometry: data.region.geojson,
                        properties: {},
                    },
                ],
            },
            style: {
                fillColor: "#B3B6BA",
            },
        }

        pins = locations.map(({ lat, lon }) => ({
            lng: lon,
            lat,
            color: riskCategoryCoding[riskCategory],
        }))

        return {
            categorizedRegions: { [hazard]: shadedRegion },
            categorizedPins: pins,
        }
    }, [__data, yProperty])

    return (
        <ShadedRegionsMap
            showLegend={false}
            data={categorizedRegions}
            loading={loading}
            pins={categorizedPins}
        />
    )
}

export default ShadedRegionMapWidget
